import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import BoostrapVue from "bootstrap-vue"
import router from './router'
import store from './store'
import App from './App.vue'
import f from '@/plugins/objectToFormData.js'
import singOrPlu from "@/plugins/singOrPlu"
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full'
import { required, min } from "vee-validate/dist/rules";

import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)

import './registerServiceWorker'
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BoostrapVue)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(BootstrapVueIcons)


require("./main_rules")

Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$formData = f
Vue.prototype.$singOrPlu = singOrPlu

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
