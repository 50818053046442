import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    my_tasks: [],
    last_five_doing: [],
    last_five_finished: [],
    last_five_failled: [],
    stat_finished: [
      {
        data: [],
      },
    ],

    my_projects: [],
    permissions: [],
    admin_permissions: [],
    permission: {},
    my_notes: [],

    granted_permissions: [],
    granted_permission: {},
    rejected_permissions: [],
    rejected_permission: {},
  },
  mutations: {
    FETCH_MY_PROJECTS(state, data) {
      state.my_projects = data;
    },

    FETCH_MY_NOTES(state, data) {
      state.my_notes = data;
    },

    FETCH_PERMISSIONS(state, data) {
      state.permissions = data;
    },

     FETCH_ADMIN_PERMISSIONS(state, data) {
      state.admin_permissions = data;
    },

    FETCH_ALLOWED_PERMISSIONS(state, data) {
      state.granted_permissions = data;
    },

    FETCH_REJECTED_PERMISSIONS(state, data) {
      state.rejected_permissions = data;
    },

    ALLOW_PERMISSION(state, data) {
      const itemIndex = state.permissions.findIndex((p) => p.id === data.id);
      state.permissions.splice(itemIndex, 1);
      state.granted_permissions.push(data);
    },

    REJECT_PERMISSION(state, data) {
      const itemIndex = state.permissions.findIndex((p) => p.id === data.id);
      state.permissions.splice(itemIndex, 1);
      state.rejected_permissions.push(data);
    },

    NEW_PERMISSION(state, data) {
      state.permissions.unshift(data);
    },

    ADD_TASK(state, data) {
      state.my_tasks.unshift(data);
    },
    FETCH_MY_TASKS(state, data) {
      state.my_tasks = data;
    },
    FETCH_LAST_FIVE_DOING(state, data) {
      state.last_five_doing = data;
    },
    FETCH_LAST_FIVE_FINISHED(state, data) {
      state.last_five_finished = data;
    },
    FETCH_LAST_FIVE_FAILLED(state, data) {
      state.last_five_failled = data;
    },
    FETCH_STAT_FINISHED(state, data) {
      state.stat_finished[0].data = data;
    },
  },
  actions: {
    fetchMyNotes({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("user/notes")
          .then((response) => {
            commit("FETCH_MY_NOTES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    note({ commit }, note) {
      return new Promise((resolve, reject) => {
        axios
          .post("user/note/", note)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchMyProjectRelatedTasks({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("user/project/" + id + "/tasks")
          .then((response) => {
            commit("FETCH_MY_TASKS", response.data.data);
            resolve(response);
          })
          .catch((response) => {
            reject(response);
          });
      });
    },

    fetchMyProjects({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("user/projects")
          .then((response) => {
            commit("FETCH_MY_PROJECTS", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addTask({ commit }, obj) {
      return new Promise((resolve, reject) => {
        axios
          .post("task/new", obj)
          .then((response) => {
            commit("ADD_TASK", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    /* invoices */

    invoice({ commit }, task) {
      return new Promise((resolve, reject) => {
        window.open(
          axios.defaults.baseURL +
            "invoice/" +
            task.id +
            "/" +
            task.price +
            ".pdf?tva=" +
            task.tva
        );
      });
    },

    requestPermission({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("permission", data)
          .then((response) => {
            commit("NEW_PERMISSION", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchPermissions({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("user/" + id + "/permissions")
          .then((response) => {
            commit("FETCH_PERMISSIONS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allPermissions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("permission/all")
          .then((response) => {
            commit("FETCH_ADMIN_PERMISSIONS", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportUsersExcel({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("user/export-excel")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportUsersPdf({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("user/export-pdf")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allowPermission({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("permission/" + id + "/allow")
          .then((response) => {
            commit("ALLOW_PERMISSION", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allAllowedPermissions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("permission/allowed")
          .then((response) => {
            commit("FETCH_ALLOWED_PERMISSIONS", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    rejectPermission({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("permission/" + id + "/reject")
          .then((response) => {
            commit("REJECT_PERMISSION", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allRejectedPermissions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("permission/rejected")
          .then((response) => {
            commit("FETCH_REJECTED_PERMISSIONS", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    statFinished() {
      return new Promise((resolve, reject) => {
        axios
          .get("user/stat-finished")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchMyTasks({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("my-tasks")
          .then((response) => {
            resolve(response);

            commit("FETCH_MY_TASKS", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchLastFiveDoing({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/lastFiveDoingTasks")
          .then((response) => {
            resolve(response);

            commit("FETCH_LAST_FIVE_DOING", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchLastFiveFinished({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/lastFiveFinishTasks")
          .then((response) => {
            resolve(response);

            commit("FETCH_LAST_FIVE_FINISHED", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchLastFiveFailled({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/failledTasks")
          .then((response) => {
            resolve(response);

            commit("FETCH_LAST_FIVE_FAILLED", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchStatFinished({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/stat-finished")
          .then((response) => {
            resolve(response);
            commit("FETCH_STAT_FINISHED", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    userDoingTasks({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/tasks/pending")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    userFinishTasks({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/tasks/finished")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },

  getters: {
    getCustomers: (state) => {
      return state.customers;
    },
  },
};
