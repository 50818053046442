import moment from 'moment';

import axios from '@/libs/axios';

var uniqBy = require("lodash/fp/uniqBy");
var sortBy = require("lodash/fp/sortBy");

export default {
  namespaced: true,
  state: {
    customerProjectTasks: [],
    recurring_invoices: [],
    recurring_invoice_services: [],
    recurring_invoice_files: [],
    invoicesDeleted: [],
    recurringInvoicesDeleted: [],
    facture: {},
    devisDeleted: [],
    invoice: {},
    devis: [],
    lastdevis: "",
    lastinvoice: "",
    invoices: [],
    lastrecurringinvoiceNum: {},
    recurringinvoice: {},
    tasksNotBilled: [],
    allCustomerInvoices: [],
    tasksWithoutCustomer: [],
    billableTasks: [],
    billableTasksNotBilled: [],
    tasks: new Array(5),
    tasksBag: new Map(),
    changeOccured: 0,
    sendables: [],
    backuped: 0,
    query: localStorage.getItem("task_query") || "res", //all, sup, res
  },
  getters: {
    varGetLastDevis: (state) => state.lastdevis,

    varGetLastInvoice: (state) => state.lastinvoice,

    varGetLastRecurringInvoiceNum: (state) => state.lastrecurringinvoiceNum,

    varGetRecurringInvoice: (state) => state.recurringinvoice,
  },
  mutations: {
    FETCH_CUSTOMER_PROJECT_TASKS(state, data) {
      state.customerProjectTasks = data;
    },

    ADD_TASKS_BY_CUSTOMER(state, data) {
      state.customerProjectTasks.push(data);
    },

    DROP_CURRING_INVOICE_SERVICES(state, recurring_invoice_services) {
      state.recurring_invoice_services = [];
    },

    FETCH_LAST_DEVIS(state, lastdevis) {
      state.lastdevis = lastdevis;
    },

    FETCH_RECURRING_INVOICE_FILES(state, data) {
      state.recurring_invoice_files = data;
    },

    SHOW_FACTURE(state, data) {
      state.facture = data;
    },

    FETCH_LAST_INVOICE(state, lastinvoice) {
      state.lastinvoice = lastinvoice;
    },

    FETCH_LAST_RECURRING_INVOICE_NUM(state, lastrecurringinvoiceNum) {
      state.lastrecurringinvoiceNum = lastrecurringinvoiceNum;
    },

    READ_RECURRING_INVOICE(state, recurringinvoice) {
      state.recurringinvoice = recurringinvoice;
    },

    // REMOVE_DEVIS(state, data) {
    //   // const itemIndex = state.devis.findIndex(p => p.id === data.id)
    //   // state.devis.splice(itemIndex, 1)
    //   state.devisDeleted.unshift(data)
    // },

    // REMOVE_DEVIS(state, data) {
    //   // const itemIndex = state.devis.findIndex(p => p.id === data.id)
    //   // state.devis.splice(itemIndex, 1)
    //   state.invoicesDeleted.unshift(data)
    // },

    FETCH_ALLTASKS_WITHOUT_CUSTOMER(state, data) {
      state.tasksWithoutCustomer = data;
    },

    FETCH_RECURRING_INVOICES(state, data) {
      state.recurring_invoices = data;
    },

    FETCH_RECURRING_INVOICE_SERVICES(state, data) {
      state.recurring_invoice_services = data;
    },

    ADD_RECURRING_INVOICE_SERVICE(state, data) {
      state.recurring_invoice_services.push(data);
    },

    FETCH_CUSTOMER_INVOICES(state, data) {
      state.allCustomerInvoices = data;
    },

    FETCH_CUSTOMER_DEVIS(state, data) {
      state.devis = data;
    },

    FETCH_TASKS_NOT_BILLED(state, data) {
      state.tasksNotBilled = data;
    },

    GENERATE_INVOICE(state, data) {
      state.invoices.unshift(data);
    },

    FETCH_ALLINVOICES(state, data) {
      state.invoices = data;
    },

    VALIDATE_DEVIS(state, data) {
      state.invoice = data;
    },

    FETCH_INVOICES(state, data) {
      state.invoice = data;
    },

    FETCH_ALLDEVIS(state, data) {
      state.devis = data;
    },

    FETCH_DEVIS_DELETED(state, data) {
      state.devisDeleted = data;
    },

    FETCH_INVOICES_DELETED(state, data) {
      state.invoicesDeleted = data;
    },

    FETCH_RECURRING_INVOICES_DELETED(state, data) {
      state.recurringInvoicesDeleted = data;
    },

    FETCH_ALL_BILLABLE_TASKS(state, data) {
      state.billableTasks = data;
    },

    FETCH_ALL_BILLABLE_TASKS_NOT_BILLED(state, data) {
      state.billableTasksNotBilled = data;
    },

    UPDATE_TASK(state, data) {
      state.task = data;
    },

    SET_ITEMS(state, {
      date,
      data,
      from_sync
    }) {
      var existing = state.tasksBag.get(date);

      var union = [...existing, ...data];
      var uniques = [...uniqBy("id", union)];
      var pattern = /^[1-9]+[0-9]*$/;

      var blanks = uniques.filter((item) => item.name.length == 0);
      var filleds = uniques.filter((item) => item.name.length > 0);

      var new_filleds = filleds.filter((item) => {
        return pattern.test(item.id) == false;
      });
      var old_filleds = filleds.filter((item) => {
        return pattern.test(item.id) == true;
      });

      uniques = [...old_filleds, ...new_filleds, ...blanks];

      state.tasksBag.set(date, from_sync ? data : uniques);
      state.changeOccured++;
    },

    POST_FILTER(state, {
      date,
      data
    }) {
      state.tasksBag.set(date, data);
    },

    DO_BACKUP(state) {
      localStorage.setItem(
        "tasks_bag_backup",
        JSON.stringify(Array.from(state.tasksBag.entries()))
      );
      // alert("tasks backuped")
      state.backuped++;
    },

    CHECKPOINT(state, data) {
      state.tasksBag = data;
      state.changeOccured++;
    },

    SYNC(state) {
      var global = [];
      var uniques = [];
      var sendables = [];
      //step 1: put everything together
      state.tasksBag.forEach((value, key) => {
        global.unshift(...value);
      });

      //step 2: remove duplication and empty lines
      /* For api uses, and for id to be uniques, id for prefill arays should nbe string: a concat of id and d
    date */

      uniques = uniqBy("id", global);

      sendables = uniques.filter((item) => {
        return item.name.length != 0;
      });

      state.sendables = sendables;

      //ok
    },

    SET_TASK_QUERY(state, val) {
      state.query = val;
      localStorage.setItem("task_query", val);
    },
  },
  actions: {
    /* When chose a new month or year on calendar */

    compute({
      commit,
      state
    }, date) {
      state.tasksBag.clear();
      state.tasks.fill({
        checked: false,
        id: 0,
        title: "",
        masters: [],
        end_date: "",
        task: null,
        _rowVariant: "danger",
      });

      /* - let api send task for the given date */
      return new Promise((resolve, reject) => {
        var daysCount = moment(date).daysInMonth();

        var localBackup = new Map(
          JSON.parse(localStorage.getItem("tasks_bag_backup"))
        );

        if (localBackup.size == 0) {
          for (let i = 1; i <= daysCount; i++) {
            state.tasksBag.set(date + "-" + i, new Array());
          }
        } else {
          commit("CHECKPOINT", localBackup);
        }

        resolve(state.tasksBag);
      });
    },

    filter({
      commit
    }, val) {
      commit("SET_TASK_QUERY", val);
    },

    postFilter({
      commit
    }, {
      date,
      data
    }) {
      commit("POST_FILTER", {
        date: date,
        data: data
      });
    },

    getTasks({
      commit,
      state
    }, date) {
      return new Promise((resolve, reject) => {
        axios
          .get("/task/" + date + "/all")
          .then((r) => {
            commit("SET_ITEMS", {
              date: date,
              data: r.data.data,
              from_sync: false,
            });
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    /* When choose a tab, meaning a specific date, key in the map then */
    tabulate({
      commit,
      state
    }, date) {
      return new Promise((resolve, reject) => {
        let data = state.tasksBag.get(date);
        resolve(data);
      });
    },

    fill({
      commit,
      state
    }, key) {
      let id = state.tasksBag.get(key).length;
      state.tasksBag.get(key).push({
        id: -1 * id,
        _rowVariant: "",
      });
    },

    remove({
      commit,
      state
    }, {
      key,
      index
    }) {
      var task = state.tasksBag.get(key)[index];
      var pattern = /^[1-9]+[0-9]*$/;
      return new Promise((resolve, reject) => {
        if (pattern.test(task.id) === true) {
          axios
            .delete("/task/" + task.id)
            .then((r) => {
              resolve(true);
              state.tasksBag.get(key).splice(index, 1);
            })
            .catch((e) => {
              reject(false);
            });
        } else {
          state.tasksBag.get(key).splice(index, 1);
          resolve(true);
        }
      });
    },

    selectAllMaster({
      state
    }, {
      key,
      index,
      masters
    }) {
      state.tasksBag.get(key)[index].masters = masters;
    },

    selectTask({
      state
    }, {
      key,
      index,
      task
    }) {
      /* By fact, customer got nul only if it was associted by a task who 'll be nulled */
      if (task == null && state.tasksBag.get(key)[index].task.customer) {
        state.tasksBag.get(key)[index].customer = null;
      }

      if (task && !task.customer) {
        state.tasksBag.get(key)[index].customer = null;
      }

      state.tasksBag.get(key)[index].task = task;
      if (
        state.tasksBag.get(key)[index].task &&
        state.tasksBag.get(key)[index].task.customer
      ) {
        state.tasksBag.get(key)[index].customer =
          state.tasksBag.get(key)[index].task.customer;
      }
    },

    selectMaster({
      state
    }, {
      key,
      index,
      master
    }) {
      if (state.tasksBag.get(key)[index].user_id == master.id) {
        state.tasksBag.get(key)[index].user_id = null;
      } else {
        state.tasksBag.get(key)[index].user_id = master.id;
      }

      state.changeOccured++;
    },
    selectSupervisor({
      state
    }, {
      key,
      index,
      supervisor
    }) {
      if (state.tasksBag.get(key)[index].supervisor_id == supervisor.id) {
        state.tasksBag.get(key)[index].supervisor_id = null;
      } else {
        state.tasksBag.get(key)[index].supervisor_id = supervisor.id;
      }
      state.changeOccured++;
    },

    selectProject({
      state
    }, {
      key,
      index,
      project
    }) {
      state.tasksBag.get(key)[index].project_id = project.id;
      if (project.customer_id) {
        state.tasksBag.get(key)[index].customer_id = project.customer_id;
      }
      state.changeOccured++;
    },

    selectCustomer({
      state
    }, {
      key,
      index,
      customer
    }) {
      if (state.tasksBag.get(key)[index].customer_id == customer.id) {
        state.tasksBag.get(key)[index].customer_id = null;
      } else {
        state.tasksBag.get(key)[index].customer_id = customer.id;
      }
    },

    setPriority({
      state
    }, {
      key,
      index,
      priority
    }) {
      state.tasksBag.get(key)[index].priority = priority;
      state.tasksBag.get(key)[index].priorityVariant =
        priority == 1 ? "danger" : priority == 2 ? "warning" : "";
      state.tasksBag.get(key)[index].priorityLabel =
        priority == 1 ? "Très urgente" : priority == 2 ? "Urgente" : "";
    },

    backup({
      commit
    }) {
      commit("DO_BACKUP");
    },

    sync({
      commit,
      state
    }, date) {
      commit("SYNC");
      if (state.sendables.length == 0) return;

      var data = new FormData();
      state.sendables.forEach((item) => {
        data.append("tasks[]", JSON.stringify(item));
      });

      data.append("date", date);

      return new Promise((resolve, reject) => {
        axios
          .post("/task/sync", data)
          .then((r) => {
            //si c'est bon on vide le backup

            localStorage.setItem("tasks_bag_backup", null);
            commit("SET_ITEMS", {
              date: date,
              data: r.data.data,
              from_sync: true,
            });
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    saveCommonTask({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/task/common", data)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    addChangeLog({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/task/" + data.get("task_id") + "/changelog", data)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    createTaskOnlyInCustomerSpacerArea: function ({
      commit
    }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("customer/create-task", formData)
          .then((response) => {
            commit("ADD_TASKS_BY_CUSTOMER", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteChangeLog(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/task/changelog/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateChangeLog({
      commit
    }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/task/changelog/${formData.get("id")}`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    closedTask({
      commit
    }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("task/closed/" + formData.get("id"), formData)
          .then((r) => {
            resolve(r);
            commit("UPDATE_TASK", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    finishedTask({
      commit
    }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("task/finished/" + formData.get("id"), formData)
          .then((r) => {
            resolve(r);
            commit("UPDATE_TASK", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getChangeLogs({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/task/" + id + "/changelogs")
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    allBillableTasks({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/task/tasks/" + id)
          .then((r) => {
            commit("FETCH_ALL_BILLABLE_TASKS", r.data.data);
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    allBillableTasksNotBilled({
      commit
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/billable-tasks-not-billed")
          .then((r) => {
            commit("FETCH_ALL_BILLABLE_TASKS_NOT_BILLED", r.data);
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    allTasksNotBilled({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/not-billed/" + id)
          .then((r) => {
            commit("FETCH_TASKS_NOT_BILLED", r.data);
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    customerInvoices({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/invoice/" + id)
          .then((r) => {
            commit("FETCH_CUSTOMER_INVOICES", r.data);
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    myActivities({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/activities")
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    taskBillable(ctx, {
      task
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/task/billable/" + task.id + "?_method=put", task)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    tasksWithoutCustomerFilter({
      commit
    }, {
      table_size,
      who_tasks,
      page = 1
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/task/without_customer?&table_size=${table_size}&who_tasks=${who_tasks}&page=${page}`
          )
          .then((response) => {
            resolve(response);
            commit("FETCH_ALLTASKS_WITHOUT_CUSTOMER", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    taskInvoice({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/generate-invoice", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    generateInvoiceFile({
      commit
    }, invoice) {
      return new Promise((resolve, reject) => {
        axios
          .put("/update-invoice/" + invoice.id, invoice)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    generateCancelledInvoice({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/generate-canceled-invoice", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    checkInvoiceIsPaid({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post("/check-invoice-paid/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allTasksDevis({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/generate-devis", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allBillableTasksFilter({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/task/billable/" + data)
          .then((response) => {
            resolve(response);
            commit("FETCH_ALLTASKS_WITHOUT_CUSTOMER", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allInvoices({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-all-invoices", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_ALLINVOICES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateThisInvoice({
      commit
    }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/update-invoice/" + formData.get("id"), formData)
          .then((response) => {
            resolve(response);
            //commit("GENERATE_INVOICE", response.data)
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    removeThisDevis({
      commit
    }, num) {
      return new Promise((resolve, reject) => {
        axios
          .get("remove-devis/" + num)
          .then((response) => {
            resolve(response);
            //commit('REMOVE_DEVIS', response.data)
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    removeThisInvoice({
      commit
    }, num) {
      return new Promise((resolve, reject) => {
        axios
          .get("remove-invoice/" + num)
          .then((response) => {
            resolve(response);
            //commit('REMOVE_DEVIS', response.data)
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    removeThisRecurringInvoice({
      commit
    }, num) {
      return new Promise((resolve, reject) => {
        axios
          .get("remove-recurring-invoice/" + num)
          .then((response) => {
            resolve(response);
            //commit('REMOVE_DEVIS', response.data)
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allRecurringInvoicesDeleted({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/recurring-invoices-deleted", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_RECURRING_INVOICES_DELETED", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allInvoicesCanceled({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/invoices-canceled", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_INVOICES_DELETED", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    restoreThisRecurringInvoice({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("restore-recurring-invoice/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    cancelThisInvoice({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`invoice/${id}/canceled`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    forceDeleteRecurringInvoice({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("force-delete-recurring-invoice/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    forceDeleteInvoice({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("force-delete-invoice/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    forceDeleteDevis({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("force-delete-devis/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    restoreThisInvoice({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("restore-invoice/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    restoreThisDevis({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("restore-devis/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getDevisCustomer({
      commit
    }, num) {
      return new Promise((resolve, reject) => {
        axios
          .get("devis-customer/" + num)
          .then((response) => {
            commit("VALIDATE_DEVIS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    sendCodeToCustomer({
      commit
    }, num) {
      return new Promise((resolve, reject) => {
        axios
          .get("send-code-to-customer/" + num)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    checkCodeToVerifyCustomer({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("check-customer-verification-code/" + data.get('customer_id'), data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getLastDevis: function ({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-last-devis", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_LAST_DEVIS", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getLastInvoice: function ({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-last-invoice", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_LAST_INVOICE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getLastRecurringInvoiceNum: function ({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-last-recurring-invoice-num", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_LAST_RECURRING_INVOICE_NUM", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getRecurringInvoice: function ({
      commit
    }, num, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-recurring-invoice/" + num, data)
          .then((response) => {
            resolve(response);
            commit("READ_RECURRING_INVOICE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCustomerInvoices: function ({
      commit
    }, customerId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/get-customer-invoices?customer_id=${customerId}`)
          .then((response) => {
            resolve(response);
            commit("FETCH_CUSTOMER_INVOICES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCustomerDevis: function ({
      commit
    }, {
      customerId,
      search
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/get-customer-devis?customer_id=${customerId}&search=${search}`)
          .then((response) => {
            resolve(response);
            commit("FETCH_CUSTOMER_DEVIS", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allDevis({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-all-devis", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_ALLDEVIS", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allInvoicesDeleted({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/invoices-deleted", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_INVOICES_DELETED", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allDevisDeleted({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/devis-deleted", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_DEVIS_DELETED", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    devisValidate({
      commit
    }, {devis_num, email}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`validate-devis/${devis_num}?email=${email}`)
          .then((response) => {
            commit("FETCH_INVOICES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    devisReset({
      commit
    }, num) {
      return new Promise((resolve, reject) => {
        axios
          .get("reset-devis/" + num)
          .then((response) => {
            commit("FETCH_INVOICES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    sendInvoiceToCustomer({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/send-invoice/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    shareInvoiceToAnyOne({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/share-to-anyone/email", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    shareDevisToAnyOne({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/share-devis-to-anyone/email", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    shareRecurringInvoiceToAnyOne({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/share-to-anyone/recurring-invoice", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    sendValidatedInvoiceToCustomer({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/send-validated-invoice/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createRecurringInvoice({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/create-recurring-invoice", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createRecurringInvoiceService({
      commit
    }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "/create-prestation/" + formData.get("recurring_invoice_id"),
            formData
          )
          .then((response) => {
            resolve(response);
            commit("ADD_RECURRING_INVOICE_SERVICE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchRecurringInvoiceServices({
      commit
    }, num) {
      return new Promise((resolve, reject) => {
        axios
          .get("/recurring-invoice-prestations/" + num)
          .then((response) => {
            resolve(response);
            commit("FETCH_RECURRING_INVOICE_SERVICES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchRecurringInvoiceFiles({
      commit
    }, num) {
      return new Promise((resolve, reject) => {
        axios
          .get("/get-recurring-files/" + num)
          .then((response) => {
            resolve(response);
            commit("FETCH_RECURRING_INVOICE_FILES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchRecurringInvoices({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/recurring-invoices", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_RECURRING_INVOICES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showRecurringInvoice({
      commit
    }, num) {
      return new Promise((resolve, reject) => {
        axios
          .get("/show-recurring-invoice/" + num)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showThisInvoice({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/show-this-invoice/" + id)
          .then((response) => {
            resolve(response);
            commit("SHOW_FACTURE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editRecurringInvoice({
      commit
    }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/edit-recurring-invoice/" + formData.get("num"), formData)
          .then((r) => {
            resolve(r);
            commit("DROP_CURRING_INVOICE_SERVICES", r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    editRecurringInvoicePrestation({
      commit
    }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/edit-prestation/" + formData.get("id"), formData)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    editTaskForDevisAndInvoice({
      commit
    }, formData) {
      formData.append("_method", "PUT");
      formData.delete("start_date");
      formData.delete("is_finished");
      formData.delete("end_date");
      formData.delete("customer_id");
      formData.delete("project");
      formData.delete("user_id");
      formData.delete("supervisor_id");
      formData.delete("master");
      formData.delete("billable");
      return new Promise((resolve, reject) => {
        axios
          .post("/task/edit/" + formData.get("id"), formData)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    deleteRecurringInvoicePrestation({
      commit
    }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/delete-prestation/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    taskChecked(ctx, {
      task,
      userInfo
    }) {
      if (task.supervisor_id == userInfo.id && task.user_id == userInfo.id) {
        task.is_finished = task.is_closed;
      }
      if (task.supervisor_id == null || (task.is_finished == 1 && userInfo.id == 19) || (task.is_finished == 1 && userInfo.id == 5)) {
        return new Promise((resolve, reject) => {
          axios
            .post("/task/closed/" + task.id + "?_method=put")
            .then((r) => {
              resolve(r);
            })
            .catch((e) => {
              reject(e);
            });
        });
      }
      if (task.supervisor_id != null && task.supervisor_id != userInfo.id) {
        return new Promise((resolve, reject) => {
          axios
            .post("/task/finished/" + task.id + "?_method=put")
            .then((r) => {
              resolve(r);
            })
            .catch((e) => {
              reject(e);
            });
        });
      }
      if ((task.supervisor_id != null && task.supervisor_id == userInfo.id) || (task.is_finished == 1 && userInfo.id == 19) || (task.is_finished == 1 && userInfo.id == 5)) {
        return new Promise((resolve, reject) => {
          axios
            .post("/task/closed/" + task.id + "?_method=put")
            .then((r) => {
              resolve(r);
            })
            .catch((e) => {
              reject(e);
            });
        });
      }
      // return new Promise((resolve, reject) => {
      //   axios
      //     .post("/task/" + task.id + "?_method=put", task)
      //     .then((r) => {
      //       resolve(r);
      //     })
      //     .catch((e) => {
      //       reject(e);
      //     });
      // });
    },

    exportInvoiceToExcel({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/export-invoices-excel", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportInvoiceToPdf({
      commit
    }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/export-invoices-pdf", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportAllInvoicesPdf({
      commit
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/export-all-invoices-pdf")
          .then((response) => resolve(response.data))
          .catch((error) => reject(error));
      });
    },

    getCustomerProjectTasks({
      commit
    }, project_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customer/${project_id}/tasks`)
          .then((response) => {
            commit("FETCH_CUSTOMER_PROJECT_TASKS", response.data);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    editTask(commit, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/customer/${formData.get("id")}/edit`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    rateProject(commit, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/customer/task/${formData.get("task_id")}/rate`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    sendAlertForUnpaidInvoices({
      commit
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/alert-unpaid-invoices")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    invoiceGlobalFilter({
      commit
    }, {
      customerId,
      startDate,
      endDate,
      paid
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/invoice-filter?customer_id=${customerId}&start_date=${startDate}&end_date=${endDate}&paid=${paid}`
          )
          .then((response) => {
            resolve(response);
            commit("FETCH_ALLINVOICES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};