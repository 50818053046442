import axiosIns from "@/libs/axios";

export default {
    namespaced: true,
    state: {
        advances: [],
        customerAdvances: [],
    },
    mutations: {
        SET_ADVANCE_PAYMENTS(state, data) {
            state.advances = data;
        },
        
        SET_CUSTOMER_ADVANCE_PAYMENTS(state, data) {
            state.customerAdvances = data;
        },
    },
    actions: {
        allAdvancePayments({commit}) {
            return new Promise((resolve, reject) => {
                axiosIns.get('/advance-payments').then(r => {
                    resolve(r.data);
                    commit('SET_ADVANCE_PAYMENTS', r.data)
                }).catch(err => {
                    reject(err);
                })
            })
        },

        allCustomerAdvancePayments({commit}, id) {
            return new Promise((resolve, reject) => {
                axiosIns.get(`customer/${id}/advance-payments`)
                .then(r => {
                    resolve(r.data);
                    commit('SET_CUSTOMER_ADVANCE_PAYMENTS', r.data);
                }).catch(error => reject(error));
            });
        },
        
        addAdvancePayment({commit},data) {
            return new Promise((resolve, reject) => {
                axiosIns.post('/advance-payment', data).then(r => {
                    resolve(r.data);
                }).catch(err => {
                    reject(err);
                })
            })
        },  

        deleteAdvancePayment({commit},id) {
            return new Promise((resolve, reject) => {
                axiosIns.delete(`/advance-payment/${id}`).then(r => {
                    resolve(r.data);
                }).catch(err => {
                    reject(err);
                })
            })
        },
    }
}