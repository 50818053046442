
export default function from(object) {
    var data = new FormData();

    var keys = Object.keys(object)
    var values = Object.values(object)

    for (var i = 0; i < keys.length; i++) {
      data.append(keys[i], values[i])


    }
    return data;

}



