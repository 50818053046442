const { required,
    email, min, confirmed, regex,
    between, alpha, alpha_dash, alpha_num, integer,
    digits, length } = require("vee-validate/dist/rules")
const { extend } = require("vee-validate/dist/vee-validate.full")

extend('required', {
    ...required,
    message: "Le champs {_field_} est requis",
})

 extend('email', {
    ...email,
    message: "Le champs {_field_} n'est pas valide."
})

extend('min', {
    ...min,

    message: "Le champs {_field_} doit avoir au moins {length} caractères."
})

extend('confirmed', {
    ...confirmed,
    message: "Les deux mots de passe ne sont pas identiques."
})

extend('regex', regex)
extend('between', between)
extend('alpha', alpha)
extend('integer', integer)

extend('digits', digits)

extend('alpha-dash', alpha_dash)

extend('alpha-num', alpha_num)

extend('length', length)

