import axios from "../../libs/axios";

export default {
    namespaced: true,
    state: {
        alerts: [],
    },
    getters: {},
    mutations: {

        ADD_ALERT(state, data) {
            state.alerts.push(data)
        },
        FETCH_ALERTS(state, data) {
            state.alerts = data
        },

        DELETE_ALERT(state, data) {
            const itemIndex = state.alerts.findIndex(p => p.id === data.id)
            state.alerts.splice(itemIndex, 1)
        },

        UPDATE_ALERT(state, data) {
            state.alert = data
        },

        SHOW_ALERT(state, alert) {
            state.alert = alert
        },

    },



    actions: {


        addAlert: function ({ commit }, formData) {
            return new Promise((resolve, reject) => {
                axios.post('/alert', formData)
                    .then((response) => {
                        resolve(response)
                        commit("ADD_ALERT", response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        showAlert: function ({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.get('/alert/' + id)
                    .then((response) => {
                        resolve(response.data)
                        
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        updateAlert({ commit }, formData) {
            formData.append('_method', 'PUT')
            return new Promise((resolve, reject) => {
                axios.post('alert/' + formData.get('id'), formData).then((r) => {
                    resolve(r)
                    
                    commit("UPDATE_ALERT", r.data.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },

        deleteAlert: function ({ commit }, id) {
            return new Promise((resolve, reject) => {
                axios.delete('/alert/' + id)
                    .then((response) => {
                        resolve(response)
                        commit("DELETE_ALERT", response.data)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        fetchAlertsInProgress({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/coming').then(r => {
                    resolve(r)
                    commit("FETCH_ALERTS", r.data.data)
                }).catch(e => {
                    reject(e)
                })
            })
        },

        fetchAlerts({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/alerts').then(r => {
                    resolve(r)
                    
                    commit("FETCH_ALERTS", r.data.data)
                }).catch(e => {
                    reject(e)
                })
            })
        },


    },
}


