import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    token: localStorage.getItem("token"),
    user: {},
    user_stats: {},
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
    countries: [],
    roles: [],
    users: [],
    timesheets: [],
    enterprises: [],
  },

  mutations: {
    ADD_COLLABORATOR(state, data) {
      state.users.push(data);
    },

    FETCH_ENTERPRISES(state, data) {
      state.enterprises = data;
    },

    FETCH_COUNTRIES(state, data) {
      state.countries = data;
    },

    FETCH_ROLES(state, data) {
      state.roles = data;
    },

    FETCH_USERS(state, data) {
      state.users = data;
    },

    UPDATE_ENTERPRISE_USER(state, data) {
      state.users = data;
    },

    SHOW_USER(state, data) {
      state.user = data;
    },

    SHOW_USER_STATS(state, data) {
      state.user_stats = data;
    },

    SET_USER(state, user) {
      state.user = user;
    },

    INIT_TOKEN(state, value) {
      state.token = value;
      localStorage.setItem("token", value);
    },

    UPDATE_USER_INFO(state, data) {
      localStorage.setItem("userInfo", JSON.stringify(data));
      state.userInfo = data;
    },

    UPDATE_ENTERPRISE_INFO(state, data) {
      localStorage.setItem("enterpriseInfo", JSON.stringify(data));
    },

    UPDATE_BANK_INFO(state, data) {
      localStorage.setItem("bankInfo", JSON.stringify(data));
    },

    LOGOUT(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("isLogged");
      localStorage.removeItem("role");
      localStorage.removeItem("global_messages");
    },

    MY_TIMESHEETS(state, data) {
      state.timesheets = data;
    },
  },

  actions: {
    vueTourState({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("vue-state", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // async fetchUser({
    //   commit
    // }) {
    //   try {
    //     const {
    //       data
    //     } = await axios.get('me')
    //     commit('SET_USER', data)
    //   } catch (e) { }
    // },

    login: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/login", formData)
          .then((response) => {
            /*             commit('INIT_TOKEN', response.data.access_token)
             */
            commit("UPDATE_ENTERPRISE_INFO", response.data.enterprise);
            commit("UPDATE_BANK_INFO", response.data.bank);
            commit("UPDATE_USER_INFO", response.data.user);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("me")
          .then((response) => {
            commit("UPDATE_USER_INFO", response.data.user);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    initUserInfo({ commit }, user) {
      commit("UPDATE_USER_INFO", user);
    },

    logout: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/logout")
          .then((response) => {
            resolve(response);
            commit("LOGOUT");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    logoutCustomer: function () {
      return new Promise((resolve, reject) => {
        axios
          .get("/customer/logout")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },


    modifyLoginData: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/member/modifyLoginData", formData)
          .then((response) => {
            commit("LOGOUT");

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    modifyUser({ commit }, obj) {
      return new Promise((resolve, reject) => {
        axios
          .post("modify/member/" + obj.id, obj)
          .then((response) => {
            commit("UPDATE_USER_INFO", response.data.user);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateUser({ commit }, formData) {
      formData.append("_method", "PUT");
      formData.delete("salary");
      formData.delete("starting_date");
      formData.delete("status"),
        formData.delete("online"),
        formData.delete("birthdate");
      formData.delete("total_hour");
      formData.delete("is_supervisor");
      return new Promise((resolve, reject) => {
        axios
          .post("enterprise/user/" + formData.get("id"), formData)
          .then((r) => {
            resolve(r);
            commit("UPDATE_USER_INFO", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    updateEnterpriseUser({ commit }, formData) {
      formData.append("_method", "PUT");
      formData.delete("salary");
      formData.delete("starting_date");
      formData.delete("birthdate");
      formData.delete("total_hour");
      return new Promise((resolve, reject) => {
        axios
          .post("enterprise/user/" + formData.get("id"), formData)
          .then((r) => {
            resolve(r);
            commit("UPDATE_ENTERPRISE_USER", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    codeReturn({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("code-return", formData)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    passwordForgotten({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("password-forgotten", formData)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    accountValidate({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/account-verify", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    changePassword({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/change-password", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    registerAdmin: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/register", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("enterprise/users")
          .then((r) => {
            resolve(r);

            commit("FETCH_USERS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    showUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("user/show/" + id)
          .then((r) => {
            resolve(r);

            commit("SHOW_USER", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    showUserStats({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`user/show-stats/${id}`)
          .then((r) => {
            resolve(r);

            commit("SHOW_USER_STATS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    validateUser: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/admin/validate", formData)
          .then((response) => {
            commit("UPDATE_USER_INFO", response.data.user);
            commit("INIT_TOKEN", response.data.access_token);
            commit("UPDATE_ENTERPRISE_INFO", response.data.enterprise);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    validateMember: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/member/validate", formData)
          .then((response) => {
            commit("INIT_TOKEN", response.data.access_token);
            commit("UPDATE_USER_INFO", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCountries: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/countries")
          .then((response) => {
            commit("FETCH_COUNTRIES", response.data);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getRoles: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/roles")
          .then((response) => {
            commit("FETCH_ROLES", response.data);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    saveEnterprise: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("enterprise", data)
          .then((response) => {
            commit("UPDATE_ENTERPRISE_INFO", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    loginCustomer: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/login/customer", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    validateCustomer: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/customer/validate", data)
          .then((response) => {
            commit("INIT_TOKEN", response.data.token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    passwordForgetten: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/password/forget", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    resetPassword: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/password/reset", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    modifyCustomerData: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/customer/info/modify", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getTimesheets({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/timesheets")
          .then((r) => {
            resolve(r);
            commit("MY_TIMESHEETS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    userEnterprises({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("all/user/enterprises")
          .then((r) => {
            resolve(r);
            commit("FETCH_ENTERPRISES", r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    switchEnterprise({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`user/enterprise/${id}/switch`)
          .then((response) => {
            resolve(response);
            commit("UPDATE_USER_INFO", response.data.data);
            commit("UPDATE_ENTERPRISE_INFO", response.data.data.enterprise);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },

  getters: {
    user: (state) => state.user,
    getToken: (state) => state.token,
    check: (state) => state.user !== null,
    isAdmin: (state) => {
      if (state.user) {
        return state.user.user.role.id == 1 ? true : false;
      } else {
        return false;
      }
    },
    isMember: (state) => {
      if (state.user) {
        return state.user.user.role.id == 2 ? true : false;
      } else {
        return false;
      }
    },
  },
};
