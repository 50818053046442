function test({ to, from, next }, canGoNext = true) {
  if (canGoNext) return next();
}

function auth({ to, from, next, router }, canGoNext = true) {
  var token = localStorage.getItem("token");

  if (token == null) {
    return router.replace("/login").catch((e) => {});
  }
  if (canGoNext) {
    return next();
  }
}

function customer_auth({ to, from, next, router }, canGoNext = true) {
  var token = localStorage.getItem("token");

  if (token == null || token == "undefined") {
    return router.replace("/login").catch((e) => {});
  }
  if (canGoNext) {
    return next();
  }
}

function has_enterprise({ next, router }, canGoNext = true) {
  var enterprise = JSON.parse(localStorage.getItem("enterpriseInfo"));
  if (enterprise == null) {
    return router.replace("/get-started").catch((e) => {});
  }

  if (canGoNext) {
    return next();
  }
}

function guest({ next, router }, canGoNext = true) {
  var token = localStorage.getItem("token");
  if (token != null) return router.replace("/").catch((e) => {});
  if (canGoNext) return next();
}

function belongsKy({ next, router }, canGoNext = true) {
  var enterprise = JSON.parse(localStorage.getItem("enterpriseInfo"));
  if (enterprise.id !== 4) return router.replace("/").catch((e) => {});
  if (canGoNext) return next();
}

function customer_guest({ next, router }, canGoNext = true) {
  var token = localStorage.getItem("token");
  if (token != null) return router.replace("/customer-space").catch((e) => {});
  if (canGoNext) return next();
}

/* function nav_watcher({ to, from, next, router }, canGoNext = true) {

    if (from.path == "/projects" || from.path == "/tasks") {
        localStorage.setItem("from_projects", 1)
    } else {
        localStorage.setItem("from_projects", 0)
    }
    if (canGoNext) return next()

    
} */

export {
  test,
  auth,
  guest,
  has_enterprise,
  customer_auth,
  customer_guest,
  belongsKy,
};
