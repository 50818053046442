import axios from "../../libs/axios";

export default {
    namespaced: true,
    state: {
        banks: [],
        bank: {},
    },
    getters: {},
    mutations: {

        UPDATE_BANK_INFO(state, data) {
            localStorage.setItem("bankInfo", JSON.stringify(data));
        },

        SHOW_BANK_INFOS(state, bank) {
            state.bank = bank
        },

    },



    actions: {

        addBankInfos: function ({ commit }, formData) {
            return new Promise((resolve, reject) => {
                axios.post('/bank', formData)
                    .then((response) => {
                        resolve(response)
                        commit("UPDATE_BANK_INFO", response.data.data);
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        showBankInfos: function ({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/bank')
                    .then((response) => {
                        resolve(response.data)
                        commit("SHOW_BANK_INFOS", response.data.data);
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        updateBankInfos({ commit }, formData) {
            formData.append('_method', 'PUT')
            return new Promise((resolve, reject) => {
                axios.post('/bank', formData).then((response) => {
                    resolve(response)
                    commit("UPDATE_BANK_INFO", response.data.data);
                }).catch((e) => {
                    reject(e)
                })
            })
        },

    },
}


