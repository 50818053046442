import Vue from "vue";

// axios
import axios from "axios";

import store from "@/store/index";

const baseURL = {
  locale: "http://127.0.0.1:8000",
  remote: "http://192.168.100.128:8000",
  live: "https://api-v2.ekoworking.com",
};
const axiosIns = axios.create({
  baseURL: baseURL.live,
});

axiosIns.interceptors.request.use((config) => {
  config.headers = {
    Authorization: "Bearer " + store.state.auth.token,
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return config;
});

axiosIns.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      localStorage.clear();
      location.replace("/login");
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
