import axios from "../../libs/axios";
import { mapActions } from "vuex";
export default {
  namespaced: true,
  state: {
    currentTimesheet: null,
  },
  getters: {},
  mutations: {
    SET_LAST_ACTION(state, data) {
      state.currentTimesheet = data;
    },
  },

  actions: {
    ...mapActions("auth", ["initUserInfo"]),
    getcurrentTimesheet({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("user/last-action")
          .then((r) => {
            resolve(r);
            commit("SET_LAST_ACTION", r.data.data);
            localStorage.userInfo = JSON.stringify(r.data.user);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    greet({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("hello")
          .then((r) => {
            resolve(r);
            commit("SET_LAST_ACTION", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
