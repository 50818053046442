import axios from "../../libs/axios";

export default {
  namespaced: true,
  state: {
    stats: {},
    employees: [],
    outEmployees: [],
    employeesWithStats: [],
    employeesWithAbsenceValidations: [],
    enterpriseStats: [],
    countStats: [],
    services: [],
    fonctions: [],
    contracts: [],
    timesheets: [],
    allTimesheets: [],
    clients: [],
    myEnterprises: [],
    employees_meta: {},
    alltasks: [],
    supervisors: [],
    allEnterprises: [],
    enterprise: {},
    settings: [],
  },
  getters: {},
  mutations: {
    ADD_CUSTOMER(state, data) {
      state.clients.unshift(data);
    },
    FETCH_CUSTOMERS(state, data) {
      state.clients = data;
    },

    FETCH_USER_ENTERPRISES(state, data) {
      state.myEnterprises = data;
    },

    SHOW_CUSTOMER(state, data) {
      state.client = data;
    },

    UPDATE_CUSTOMER(state, data) {
      const itemIndex = state.clients.findIndex((p) => p.id === data.id);
      Object.assign(state.clients[itemIndex], data);
    },

    DELETE_CUSTOMER(state, data) {
      const itemIndex = state.clients.findIndex((p) => p.id === data.id);
      state.clients.splice(itemIndex, 1);
    },

    ADD_COLLABORATOR(state, data) {
      state.employees.unshift(data);
    },

    ADD_SERVICE(state, data) {
      state.services.unshift(data);
    },
    FETCH_ALLTASKS(state, data) {
      state.alltasks = data;
    },

    FETCH_SUPERVISORS(state, data) {
      state.supervisors = data;
    },

    FETCH_CONTRACTS(state, data) {
      state.contracts = data;
    },

    DELETE_SERVICE(state, data) {
      const itemIndex = state.services.findIndex((p) => p.id === data.id);
      state.services.splice(itemIndex, 1);
    },

    ADD_FONCTION(state, data) {
      state.fonctions.unshift(data);
    },

    FETCH_STATS(state, data) {
      state.stats = data;
    },
    UPDATE_SERVICE(state, data) {
      const itemIndex = state.services.findIndex((p) => p.id === data.id);
      Object.assign(state.services[itemIndex], data);
    },

    UPDATE_FONCTION(state, data) {
      const itemIndex = state.fonctions.findIndex((p) => p.id === data.id);
      Object.assign(state.fonctions[itemIndex], data);
    },

    FETCH_SERVICES(state, data) {
      state.services = data;
    },

    FETCH_FONCTIONS(state, data) {
      state.fonctions = data;
    },
    SHOW_FONCTION(state, data) {
      state.fonction = data;
    },

    SHOW_SERVICE(state, service) {
      state.service = service;
    },

    FETCH_EMPLOYEES(state, data) {
      state.employees = data;
      state.timesheets = [];
      data.forEach((item) => {
        var items = item.timesheets;
        state.timesheets.push(...items);
      });
    },

    FETCH_OUT_EMPLOYEES(state, data) {
      state.outEmployees = data;
      state.timesheets = [];
      data.forEach((item) => {
        var items = item.timesheets;
        state.timesheets.push(...items);
      });
    },

    FETCH_EMPLOYEES_WITH_STATS(state, data) {
      state.employeesWithStats = data;
    },

    FETCH_ENTERPRISE_STATS(state, data) {
      state.enterpriseStats = data;
    },

    FETCH_INVOICES_STATS(state, data) {
      state.countStats = data;
    },

    EMPLOYEES_META(state, data) {
      state.employees_meta = data;
    },

    FETCH_ABSENCE_VALIDATIONS(state, data) {
      state.employeesWithAbsenceValidations = data;
    },

    FETCH_TIMESHEETS(state, data) {
      state.allTimesheets = data;
    },

    FETCH_ENTERPRISES(state, data) {
      state.allEnterprises = data;
    },

    GET_ENTERPRISE_BY_ID(state, data) {
      state.enterprise = data;
    },

    UPDATE_ENTERPRISE_SETTINGS(state, data) {
      state.settings = data;
    },
  },

  actions: {
    getStats({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("enterprise/stats")
          .then((r) => {
            resolve(r);
            commit("FETCH_STATS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    enterpriseUser: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("enterprise/user", formData)
          .then((r) => {
            resolve(r);

            commit("ADD_COLLABORATOR", r.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addService: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/service", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_SERVICE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showService: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/service/" + id)
          .then((response) => {
            resolve(response);
            commit("SHOW_SERVICE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateService({ commit }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("service/" + formData.get("id"), formData)
          .then((r) => {
            resolve(r);

            commit("UPDATE_SERVICE", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    deleteService: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/service/" + id)
          .then((response) => {
            resolve(response);
            commit("DELETE_SERVICE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchServices({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/services")
          .then((r) => {
            resolve(r);
            commit("FETCH_SERVICES", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    fetchThisService({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("fonction/service/" + id)
          .then((r) => {
            resolve(r);
            commit("SHOW_SERVICE", r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    addFonction: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/fonction", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_FONCTION", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchFonctions({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("fonctions/" + id)
          .then((r) => {
            resolve(r);
            commit("FETCH_FONCTIONS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    showFonction: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/fonction/" + id)
          .then((response) => {
            resolve(response);
            commit("SHOW_FONCTION", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateFonction({ commit }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("fonction/" + formData.get("id"), formData)
          .then((r) => {
            resolve(r);

            commit("UPDATE_FONCTION", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getEmployees({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("enterprise/all-users")
          .then((r) => {
            resolve(r);

            commit("FETCH_EMPLOYEES", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getUsersWithAbsencesValidations({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("user/absence-validations")
          .then((response) => {
            commit("FETCH_ABSENCE_VALIDATIONS", response.data.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getOutEmployees({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("enterprise/all-out-users")
          .then((r) => {
            resolve(r);

            commit("FETCH_OUT_EMPLOYEES", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getEmployeesWithStats({ commit }, { page = 1, user_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`enterprise/users-with-stats?user_id=${user_id}&page=${page}`)
          .then((r) => {
            resolve(r);

            commit("EMPLOYEES_META", r.data.meta);
            commit("FETCH_EMPLOYEES_WITH_STATS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getEnterpriseStats({ commit }, { page = 1, user_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`enterprise/users-with-stats?user_id=${user_id}&page=${page}`)
          .then((r) => {
            resolve(r);
            commit("EMPLOYEES_META", r.data.meta);
            commit("FETCH_ENTERPRISE_STATS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getCountStats({ commit }, { from, to }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`enterprise/users-stats?from=${from}&to=${to}`)
          .then((r) => {
            resolve(r);
            commit("FETCH_INVOICES_STATS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    isAlreadyHired({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post("user/is-hire/" + id)
          .then((r) => {
            resolve(r);
            // commit("UPDATE_SERVICE", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getUserEnterprises({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/all-enterprises")
          .then((response) => {
            resolve(response);
            commit("FETCH_USER_ENTERPRISES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/enterprise")
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getContracts({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/contracts")
          .then((r) => {
            commit("FETCH_CONTRACTS", r.data);
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    updateEnterprise({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("enterprise/" + data.get("id"), data)
          .then((r) => {
            localStorage.setItem("enterpriseInfo", JSON.stringify(r.data.data));
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    addCustomer: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/customer/add", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_CUSTOMER", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchCustomers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/customer/all")
          .then((response) => {
            resolve(response);
            commit("FETCH_CUSTOMERS", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    importCustomers({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("import/customers", data)
          .then((response) => {
            resolve(response);
            commit("ADD_CUSTOMER", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showCustomer: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/customer/" + id)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateCustomer({ commit }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("customer/" + formData.get("id"), formData)
          .then((response) => {
            commit("UPDATE_CUSTOMER", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteCustomer: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/customer/" + id)
          .then((response) => {
            resolve(response);
            commit("DELETE_CUSTOMER", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    existCustomer: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/customer/exist", formData)
          .then((response) => {
            resolve(response);
            commit("SHOW_CUSTOMER", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    joinCustomer: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .post("/customer/join/" + id)
          .then((response) => {
            resolve(response);
            commit("ADD_CUSTOMER", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchAllSupervisors({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/enterprise/all-supervisors")
          .then((response) => {
            resolve(response);
            commit("FETCH_SUPERVISORS", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allTasksFilter({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/task/filter/" + data)
          .then((response) => {
            resolve(response);
            commit("FETCH_ALLTASKS", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    myGlobalFilter(
      { commit },
      {
        collaboratorId,
        supervisorId,
        startDate,
        endDate,
        table_size,
        whoTasks,
        priority,
        page = 1,
      }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/task/global-filter?user_id=${collaboratorId}&supervisor_id=${supervisorId}&start_date=${startDate}&end_date=${endDate}&table_size=${table_size}&who-tasks=${whoTasks}&priority=${priority}&page=${page}`
          )
          .then((response) => {
            resolve(response);
            commit("FETCH_ALLTASKS", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportFilter(
      { commit },
      {
        collaboratorId,
        supervisorId,
        startDate,
        endDate,
        table_size,
        whoTasks,
        priority,
        page = 1,
      }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/task/export-filter?user_id=${collaboratorId}&supervisor_id=${supervisorId}&start_date=${startDate}&end_date=${endDate}&table_size=${table_size}&who-tasks=${whoTasks}&priority=${priority}&page=${page}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchTimesheets({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`enterprise/timesheets`)
          .then((response) => {
            resolve(response.data.data);
            commit("FETCH_TIMESHEETS", response.data.data);
          })
          .catch((error) => reject(error));
      });
    },

    filterTimesheets({ commit }, { userId, startDate, endDate, absence }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `enterprise/timesheets?user_id=${userId}&absence=${absence}&start_date=${startDate}&end_date=${endDate}`
          )
          .then((response) => {
            resolve(response.data);
            commit("FETCH_TIMESHEETS", response.data.data);
          })
          .catch((error) => reject(error));
      });
    },

    exportTimesheetsToPdf({ commit }, { userId, startDate, endDate, absence }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `enterprise/timesheets/export-to-pdf?user_id=${userId}&absence=${absence}&start_date=${startDate}&end_date=${endDate}`
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    exportTimesheetsToExcel(
      { commit },
      { userId, startDate, endDate, absence }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `enterprise/timesheets/export-to-excel?user_id=${userId}&absence=${absence}&start_date=${startDate}&end_date=${endDate}`
          )
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchAllEnterprises({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`enterprises`)
          .then((response) => {
            resolve(response.data);
            commit("FETCH_ENTERPRISES", response.data.data);
          })
          .catch((error) => reject(error));
      });
    },

    getEnterpriseById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`enterprises/${id}`)
          .then((response) => {
            resolve(response.data);
            commit("GET_ENTERPRISE_BY_ID", response.data.data);
          })
          .catch((error) => reject(error));
      });
    },

    editSettings({ commit }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`enterprises/${formData.get("id")}/settings`, formData)
          .then((response) => {
            resolve(response.data);
            commit("UPDATE_ENTERPRISE_SETTINGS", response.data.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
