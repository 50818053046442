import {
  auth,
  guest,
  test,
  belongsKy,
  has_enterprise,
  customer_guest,
  customer_auth,
} from "@/middleware";

export const routes = [
  {
    path: "/contact-us",
    name: "contact-us",
    component: () =>
      import("@/views/ContactUs.vue"),
  },
  {
    path: "/task/devis",
    name: "task-devis",
    component: () =>
      import("@/views/admin/ModuleInvoice/Create/TasksDevis.vue"),
  },
  {
    path: "/task/invoice",
    name: "task-invoice",
    component: () =>
      import("@/views/admin/ModuleInvoice/Create/TasksInvoice.vue"),
  },

  {
    path: "/edit/task/devis/:id",
    name: "edit-task-devis-id",
    component: () => import("@/views/admin/ModuleInvoice/Edit/TasksDevis.vue"),
  },
  {
    path: "/edit/task/invoice/:id",
    name: "edit-task-invoice-id",
    component: () =>
      import("@/views/admin/ModuleInvoice/Edit/TasksInvoice.vue"),
  },

  {
    path: "/admin/devis",
    name: "admin-devis",
    component: () => import("@/views/admin/ModuleInvoice/Devis.vue"),
  },

  {
    path: "/devis/corbeille",
    name: "devis-corbeille",
    component: () =>
      import("@/views/admin/ModuleInvoice/corbeilles/DevisCorbeille.vue"),
  },

  {
    path: "/admin/invoices",
    name: "admin-invoices",
    component: () => import("@/views/admin/ModuleInvoice/Invoices.vue"),
  },
  {
    path: "/invoice/corbeille",
    name: "invoice-corbeille",
    component: () =>
      import("@/views/admin/ModuleInvoice/corbeilles/InvoiceCorbeille.vue"),
  },

  {
    path: "/invoices/canceled",
    name: "canceled-invoices",
    component: () =>
      import("@/views/admin/ModuleInvoice/corbeilles/CanceledInvoices.vue"),
  },

  {
    path: "/recurring-invoice/corbeille",
    name: "recurring-invoice-corbeille",
    component: () =>
      import(
        "@/views/admin/ModuleInvoice/corbeilles/RecurringInvoiceCorbeille.vue"
      ),
  },

  {
    path: "/admin/create-recurring-invoice",
    name: "admin-create-recurring-invoice",
    component: () =>
      import("@/views/admin/ModuleInvoice/Create/RecurringInvoice.vue"),
  },

  {
    path: "/admin/credit-invoices",
    name: "admin-cancelled-invoices",
    component: () =>
      import("@/views/admin/ModuleInvoice/CancelledInvoices.vue"),
  },
  {
    path: "/task/cancelled-invoice",
    name: "create-cancelled-invoice",
    component: () =>
      import("@/views/admin/ModuleInvoice/Create/CancelledInvoice.vue"),
  },

  {
    path: "/admin/recurring-invoices",
    name: "admin-recurring-invoices",
    component: () =>
      import("@/views/admin/ModuleInvoice/RecurringInvoices.vue"),
  },

  {
    path: "/admin/edit-recurring-invoice/:num",
    name: "admin-edit-recurring-invoice-num",
    component: () =>
      import("@/views/admin/ModuleInvoice/Edit/EditRecurringInvoice.vue"),
  },

  {
    path: "/admin/recurring-invoice-files/:num",
    name: "admin-recurring-invoice-files-num",
    component: () =>
      import("@/views/admin/ModuleInvoice/RecurringInvoiceFiles.vue"),
  },

  {
    path: "/invoice/validate/:num",
    name: "invoice-validate",
    component: () => import("@/views/admin/ModuleInvoice/_num.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/",
    name: "home",
    component: () => import("@/views/Dashboard.vue"),
    meta: {
      middleware: [auth, has_enterprise],
    },
  },

  {
    path: "/get-started",
    name: "get-started",
    component: () => import("@/views/admin/Enterprise.vue"),
    meta: {
      layout: "full",
      middleware: [auth],
    },
  },

  {
    path: "/get-started/next",
    name: "get-started-next",
    component: () => import("@/views/admin/SubscriptionPlan.vue"),
    meta: {
      layout: "no-sidebar",
    },
  },

  {
    path: "/task/:id/changelogs",
    name: "changelog",
    component: () => import("@/views/shared/tasks/TaskChangeLog.vue"),
  },

  {
    path: "/activities",
    name: "activities",
    component: () => import("@/views/shared/tasks/MyActivities.vue"),
  },

  {
    path: "/projects",
    name: "projects",
    component: () => import("@/views/shared/tasks/Project.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/customers",
    name: "customers",
    component: () => import("@/views/shared/tasks/Customer.vue"),
  },

  /*   {
          path: '/task/:id',
          name: 'tasks',
          component: () => import('@/views/shared/tasks/SubTasks.vue'),
      },
   */
  {
    path: "/tasks",
    name: "tasks",
    component: () => import("@/views/shared/tasks/Sheet2.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Auth/Login.vue"),
    meta: {
      layout: "full",
      middleware: [guest],
    },
  },

  {
    path: "/return-code",
    name: "return-code",
    component: () => import("@/views/Auth/ReturnCode.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/password-return-code",
    name: "password-return-code",
    component: () => import("@/views/Auth/PasswordReturnCode.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/login/return-code",
    name: "login-return-code",
    component: () => import("@/views/Auth/LoginReturnCode.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/info",
    name: "info",
    component: () => import("@/views/admin/EnterpriseInfo.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/ky-register",
    name: "ky-register",
    component: () => import("@/views/Auth/Register.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/customer",
    name: "customer",
    component: () => import("@/views/Auth/Customer.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/Auth/Forgot-password.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/account-validate",
    name: "account-validate",
    component: () => import("@/views/Auth/Account-validate.vue"),
    meta: {
      layout: "full",
      middleware: [guest],
    },
  },

  {
    path: "/account-verify",
    name: "account-verify",
    component: () => import("@/views/Auth/Account-verify.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/change-password",
    name: "change-password",
    component: () => import("@/views/Auth/ChangePassword.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/login-validate",
    name: "login-validate",
    component: () => import("@/views/Auth/LoginValidate.vue"),
    meta: {
      layout: "full",
      middleware: [],
    },
  },

  // {
  //     path: '/projet-client',
  //     name: 'projet-client',
  //     component: () => import('@/views/member/ProjetClient.vue'),
  // },
  {
    path: "/time-sheet",
    name: "time-sheet",
    component: () => import("@/views/member/TimeSheet.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/user-list",
    name: "user-list",
    component: () => import("@/views/admin/users/UserList.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/permissions",
    name: "permissions",
    component: () => import("@/views/member/Permissions.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/my_notes",
    name: "my_notes",
    component: () => import("@/views/member/MyNotes.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/deconnexion",
    name: "deconnexion",
    component: () => import("@/views/Auth/Login.vue"),
  },

  // ===========================================================
  // CUSTOMER ROUTES
  // ===========================================================
  {
    path: "/our-project",
    name: "our-project",
    component: () => import("@/views/customer/CustomerProjects.vue"),
    meta: {},
  },
  {
    path: "/project-request",
    name: "project-request",
    component: () => import("@/views/customer/ProjectRequest.vue"),
    meta: {},
  },

  {
    path: "/customer-devis/:num",
    name: "customer-devis-num",
    component: () => import("@/views/customer/CustomerDevisDetails.vue"),
    meta: {
      layout: "full",
      middleware: [customer_auth],
    },
  },

  {
    path: "/customer-space",
    name: "customer-space",
    component: () => import("@/views/customer/EspaceClient.vue"),
    meta: {
      layout: "full",
      middleware: [customer_auth],
    },
  },

  {
    path: "/customer-login",
    name: "customer-login",
    component: () => import("@/views/customer/Auth/Login.vue"),
    meta: {
      layout: "full",
      middleware: [customer_guest],
    },
  },

  {
    path: "/customer-forgot-password",
    name: "customer-forgot-password",
    component: () => import("@/views/customer/Auth/Forgot-password.vue"),
    meta: {
      layout: "full",
      middleware: [customer_guest],
    },
  },

  {
    path: "/customer-verify-account",
    name: "customer-verify-account",
    component: () => import("@/views/customer/Auth/Account-verify.vue"),
    meta: {
      layout: "full",
      middleware: [customer_guest],
    },
  },

  {
    path: "/customer-change-password",
    name: "customer-change-password",
    component: () => import("@/views/customer/Auth/ChangePassword.vue"),
    meta: {
      layout: "full",
      middleware: [customer_guest],
    },
  },

  {
    path: "/customer-validate",
    name: "customer-validate",
    component: () => import("@/views/customer/Auth/LoginValidate.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/customer-password",
    name: "customer-password",
    component: () => import("@/views/customer/Auth/LoginPassword.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/member-profile",
    name: "member-profile",
    component: () => import("@/views/member/MemberProfile.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/edit-profile",
    name: "edit-profile",
    component: () => import("@/views/customer/EditProfile.vue"),
    meta: {
      layout: "full",
    },
  },

  {
    path: "/member/edit-profile/:id",
    name: "edit-member-profile-id",
    component: () => import("@/views/member/EditMemberProfile.vue"),
  },

  //=======================

  //=====ADMIN ROUTE=======
  {
    path: "/services",
    name: "services",
    component: () => import("@/views/admin/Services.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/admin/member/profile/:id",
    name: "admin-member-profile-id",
    component: () => import("@/views/admin/users/AdminMemberProfile.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/admin/edit-profile/:id",
    name: "admin-edit-profile-id",
    component: () => import("@/views/admin/users/AdminEditMemberProfile.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/admin/users",
    name: "admin-users",
    component: () => import("@/views/admin/users/UserList.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/details-service/:id",
    name: "details-service-id",
    component: () => import("@/views/admin/DetailsService.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/permissions-management",
    name: "permissions-management",
    component: () => import("@/views/admin/PermissionsManagement.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/admin/customer",
    name: "admin-customer",
    component: () => import("@/views/admin/customers/CustomerList.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/admin/statistiques",
    name: "admin-statistiques",
    component: () => import("@/views/admin/stats/index.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/billable-task-not-billed",
    name: "billable-task-not-billed",
    component: () =>
      import("@/views/admin/ModuleInvoice/BillableTasksNotBilled.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/admin/advance-payments",
    name: "admin-advance-payments",
    component: () => import("@/views/admin/advancePayments/List.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/admin/advance-payments/new",
    name: "new-advance-payments",
    component: () => import("@/views/admin/advancePayments/New.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/admin/absence-validations",
    name: "absence-validations",
    component: () => import("@/views/admin/EmployeesAbsencesValidations.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/admin/enterprise-timesheets",
    name: "enterprise-timesheets",
    component: () => import("@/views/admin/TimeSheetTable.vue"),
    meta: {
      middleware: [auth],
    },
  },

  {
    path: "/admin/enterprises",
    name: "enterprises",
    component: () => import("@/views/admin/Enterprises.vue"),
    meta: {
      middleware: [auth, belongsKy],
    },
  },

  {
    path: "/admin/edit/enterprise/:id",
    name: "edit-enterprise",
    component: () => import("@/views/admin/EditEnterprise.vue"),
    meta: {
      middleware: [auth, belongsKy],
    },
    props: { enterprise: {} },
  },

  // ======================

  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/error/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "*",
    redirect: "error-404",
  },
];
