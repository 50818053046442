import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    notificationsEnabled: localStorage.getItem('notificationsEnabled'),
  },

  mutations: {
    ENABLE_NOTIFICATION(state, value) {
      state.notificationsEnabled = value
      localStorage.setItem('notificationsEnabled', value)
    },
  },

  actions: {
    enableNotification({
      commit
    }, value) {
      commit('ENABLE_NOTIFICATION', value)
    },

    subscribe: function ({
        commit
      }, formData) {
        return new Promise((resolve, reject) => {
          axios.post('subscription', formData)
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

    sendNotify: function ({
        commit
      }, formData) {
        return new Promise((resolve, reject) => {
          axios.post('notify', formData)
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      sendGroupNotification: function ({
        commit
      }, formData) {
        return new Promise((resolve, reject) => {
          axios.post('notify/group', formData)
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
    deleteSubscription: function ({
        commit
      }, formData) {
        return new Promise((resolve, reject) => {
          axios.post('subscription/delete', formData)
            .then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              reject(error)
            })
        })
      },

  },

  getters: {
    notificationsEnabled: state =>
      state.notificationsEnabled ? true : false,
  }

}
