<template>
  <div id="app" class="h-100" :class="[skinClasses]">
     <!-- <snow :size="4"/> -->
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import updateApp from "@core/mixins/update-app";

import { useWindowSize, useCssVar } from "@vueuse/core";
import Snow from '@core/components/Snow.vue';

import store from "@/store";
import { mapActions } from "vuex";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");
const NoSideBar = () => import("@/layouts/no-sidebar/NoSideBar.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    NoSideBar,
    ToastificationContent,
    Snow,
  },
  mixins: [updateApp],
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      switch (this.$route.meta.layout) {
        case "full":
          return "layout-full";
        case "no-sidebar":
          return "no-side-bar";
        default:
          return `layout-${this.contentLayoutType}`;
      }
      /* if (this.$route.meta.layout === "no-sidebar") return "no-side-bar";
      return `layout-${this.contentLayoutType}`; */
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },

  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },

  methods: {
    ...mapActions("projects", { backupProjects: "backup" }),
    ...mapActions("tasks", { backupTasks: "backup" }),

    saveChanges(e) {
      if (e.which == 83 && e.ctrlKey) {
        e.preventDefault();

        if (this.$route.path == "/projects") {
          this.backupProjects();
        } else {
          this.backupTasks();
        }

        return false;
      }
    },
  },

  watch: {
    updateExists(val) {
      if (val)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Nouvelle version",
              icon: "ArrowDownIcon",
              text: "Une nouvelle mise à jour est disponible. Veuillez cliquer sur le bouton indiqué afin de charger la nouvelle version.",
              variant: "info",
              buttonText: "Mettre à jour",
              buttonClick: this.refreshApp,
            },
          },
          {
            position: "bottom-left",
            timeout: 5688999,
          }
        );
    },
  },

  mounted() {
    window.addEventListener("keydown", this.saveChanges);
  },
};
</script>
