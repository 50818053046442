import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import tasks from "./tasks"
import auth from "./auth"
import timesheet from './timesheet'
import enterprise from './enterprise'
import member from './member'
import projects from "./projects"
import customer from "./customer"
import notify from "./notify"
import alert from "./alert"
import bank from "./bank"
import advancePayments from './advancePayments';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    tasks,
    auth,
    timesheet,
    enterprise,
    member,
    timesheet,
    enterprise,
    projects,
    customer,
    notify,
    alert,
    bank,
    advancePayments
  },
  strict: process.env.DEV,
})
