import axios from "../../libs/axios";

export default {
  namespaced: true,
  state: {
    stats: {},
    projects: [],
    customerInfo: JSON.parse(localStorage.getItem("customerInfo")),
    enterpriseInfo: JSON.parse(localStorage.getItem("enterpriseInfo")),
    token: localStorage.getItem("token"),
  },

  getters: {},
  mutations: {
    ADD_PROJECT(state, data) {
      state.projects.unshift(data);
    },
    FETCH_PROJECTS(state, data) {
      state.projects = data;
    },

    DELETE_PROJECT(state, data) {
      const itemIndex = state.projects.findIndex((p) => p.id === data.id);
      state.projects.splice(itemIndex, 1);
    },

    UPDATE_CUSTOMER_INFO(state, data) {
      localStorage.setItem("customerInfo", JSON.stringify(data));
      state.customerInfo = data;
    },

    UPDATE_ENTERPRISE_INFO(state, data) {
      localStorage.setItem("enterpriseInfo", JSON.stringify(data));
      state.enterpriseInfo = data;
    },

    SET_TOKEN(state, data) {
      localStorage.setItem("token", data);
      state.token = data;
    },
  },

  actions: {
    login: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("customer/login", formData)
          .then((response) => {
            /*             commit('INIT_TOKEN', response.data.access_token)*/
            commit("UPDATE_ENTERPRISE_INFO", response.data.enterprise);
            commit("UPDATE_CUSTOMER_INFO", response.data.customer);
            commit("SET_TOKEN", response.data.access_token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    codeReturn({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("customer-code-return", formData)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    accountValidate({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/customer-account-verify", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    changePassword({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/customer-change-password", formData)
          .then((response) => {
            commit("UPDATE_ENTERPRISE_INFO", response.data.enterprise);
            commit("UPDATE_CUSTOMER_INFO", response.data.customer);
            commit("SET_TOKEN", response.data.access_token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createCustomerPassword: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("customer/create-password", formData)
          .then((response) => {
            commit("UPDATE_ENTERPRISE_INFO", response.data.enterprise);
            commit("UPDATE_CUSTOMER_INFO", response.data.customer);
            commit("SET_TOKEN", response.data.access_token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCustomerByEmail: function ({ commit }, email, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customer/${email}/get`, data)
          .then((response) => {
            commit("UPDATE_ENTERPRISE_INFO", response.data.enterprise);
            commit("UPDATE_CUSTOMER_INFO", response.data.customer);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    validateCode: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("customer/validate", formData)
          .then((response) => {
            commit("SET_TOKEN", response.data.access_token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addProject: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/project", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_PROJECT", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createProject: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/customer/create-project", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_PROJECT", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showProject: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/project/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateProject({ commit }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("service/" + formData.get("id"), formData)
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    deleteProject: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/service/" + id)
          .then((response) => {
            resolve(response);
            commit("DELETE_SERVICE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchProjects({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .get("/projects")
          .then((r) => {
            resolve(r);
            commit("FETCH_PROJECTS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    fetchCustomerProjects({ commit }, { customerId, search }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/get-customer-projects?customer_id=${customerId}&search=${search}`
          )
          .then((r) => {
            resolve(r);
            commit("FETCH_PROJECTS", r.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    addFonction: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/fonction", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_FONCTION", response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchFonctions({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("fonctions/" + id)
          .then((r) => {
            resolve(r);
            commit("FETCH_FONCTIONS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    getEmployeesWithStats({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("enterprise/users-with-stats")
          .then((r) => {
            resolve(r);

            commit("FETCH_EMPLOYEES_WITH_STATS", r.data.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },

    customerResponsible({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`customer/${id}/responsible`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    changeResponsible({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`customer/responsible/change`, data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
