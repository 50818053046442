import moment from 'moment';

import axios from '@/libs/axios';

var uniqBy = require('lodash/fp/uniqBy');

export default {
  namespaced: true,
  state: {
    allprojecttasks: [],
    projects: new Array(5),
    projectsBag: new Map(),
    allprojects: [],
    changeOccured: 0,
    sendables: [],
    backuped: 0,
    comments: [],
    allCustomersProjects: [],
  },
  getters: {},
  mutations: {

    FETCH_ALL_PROJECT_TASKS(state, data) {
      state.allprojecttasks = data
    },

    FETCH_ALL_PROJECTS(state, data) {
      state.allprojects = data
    },

    FETCH_ALL_CUSTOMERS_PROJECTS(state, data) {
      state.allCustomersProjects = data
    },

    FETCH_COMMENTS(state, data) {
      state.comments = data
    },

    FETCH_ALL_ENTERPRISE_PROJECTS(state, data) {
      state.allprojects = data
    },

    SET_ITEMS(state, { date, data, from_sync }) {


      var existing = state.projectsBag.get(date)

      var union = [...existing, ...data]
      var uniques = [...uniqBy("id", union)]

      // 

      state.projectsBag.set(date, from_sync ? data : uniques)
      state.changeOccured++

    },

    DO_BACKUP(state) {
      localStorage.setItem("projects_bag_backup", JSON.stringify(Array.from(state.projectsBag.entries())))
      // alert("projects backuped")
      state.backuped++
    },

    CHECKPOINT(state, data) {
      state.projectsBag = data
      state.changeOccured++
    },

    SYNC(state) {
      var global = []
      var uniques = []
      var sendables = []
      //step 1: put everything together
      state.projectsBag.forEach((value, key) => {

        global.unshift(...value)
      })



      //step 2: remove duplication and empty lines
      /* For api uses, and for id to be uniques, id for prefill arays should nbe string: a concat of id and d
      date */

      uniques = uniqBy("id", global)


      sendables = uniques.filter((item) => {
        return item.name.length != 0
      })




      state.sendables = sendables;

      //ok
    }
  },
  actions: {
    /* When chose a new month or year on calendar */

    compute({ commit, state }, date) {

      state.projectsBag.clear();
      /* - let api send task for the given date */
      return new Promise((resolve, reject) => {
        var daysCount = moment(date).daysInMonth();

        var localBackup = new Map(JSON.parse(localStorage.getItem('projects_bag_backup')))


        if (localBackup.size == 0) {
          for (let i = 1; i <= daysCount; i++) {
            state.projectsBag.set(
              date + "-" + i,
              new Array()
            );
          }
        } else {
          commit("CHECKPOINT", localBackup)
        }

        resolve(state.projectsBag);

      });
    },

    getProjects({ commit }, date) {

      return new Promise((resolve, reject) => {
        axios.get('/project/' + date + '/all').then(r => {


          commit("SET_ITEMS", { date: date, data: r.data.data, from_sync: false })
          resolve(r)

        }).catch(e => {
          reject(e)
        })
      })

    },

    /* When choose a tab, meaning a specific date, key in the map then */
    tabulate({ commit, state }, date) {
      return new Promise((resolve, reject) => {
        let data = state.projectsBag.get(date);
        resolve(data);
      });
    },

    fill({ commit, state }, key) {
      let id = state.projectsBag.get(key).length
      state.projectsBag.get(key).push({
        id: -1 * id, _rowVariant: ''
      })
    },

    remove({ commit, state }, { key, index }) {
      var project = state.projectsBag.get(key)[index];
      var pattern = /^[1-9]+[0-9]*$/
      return new Promise((resolve, reject) => {
        if (pattern.test(project.id) === true) {

          axios.delete('/project/' + project.id).then(r => {

            resolve(true)
            state.projectsBag.get(key).splice(index, 1)
          }).catch(e => {
            reject(false)
          })

        } else {
          state.projectsBag.get(key).splice(index, 1)
          resolve(true)
        }

      })
    },


    selectAllMaster({ state }, { key, index, masters }) {

      state.projectsBag.get(key)[index].masters = masters
    },

    selectProject({ state }, { key, index, project }) {

      /* By fact, customer got nul only if it was associted by a project who 'll be nulled */
      if (project == null && state.projectsBag.get(key)[index].project.customer) {
        state.projectsBag.get(key)[index].customer = null
      }

      if (project && !project.customer) {
        state.projectsBag.get(key)[index].customer = null
      }

      state.projectsBag.get(key)[index].project = project
      if (state.projectsBag.get(key)[index].project && state.projectsBag.get(key)[index].project.customer) {
        state.projectsBag.get(key)[index].customer = state.projectsBag.get(key)[index].project.customer
      }


    },

    selectMaster({ state }, { key, index, master }) {

      if (state.projectsBag.get(key)[index].user_id == master.id) {
        state.projectsBag.get(key)[index].user_id = null
      } else {
        state.projectsBag.get(key)[index].user_id = master.id
      }
      state.changeOccured++
    },

    selectCustomer({ state }, { key, index, customer }) {
      state.projectsBag.get(key)[index].customer_id = customer.id
    },

    setPriority({ state }, { key, index, priority }) {
      state.projectsBag.get(key)[index].priority = priority
      state.projectsBag.get(key)[index]._rowVariant = priority == 1 ? 'danger' : priority == 2 ? 'warning' : priority == 3 ? 'primary' : ""
    },

    backup({ commit }) {
      commit("DO_BACKUP")
    },

    sync({ commit, state }, date) {

      commit("SYNC")
      if (state.sendables.length == 0) return


      var data = new FormData()
      state.sendables.forEach(item => {
        data.append("projects[]", JSON.stringify(item))
      })

      data.append("date", date)


      return new Promise((resolve, reject) => {
        axios.post("/project/sync", data).then((r) => {
          //si c'est bon on vide le backup 

          localStorage.setItem("projects_bag_backup", null)
          commit("SET_ITEMS", { date: date, data: r.data.data, from_sync: true })
          resolve(r)
        }).catch(e => {
          reject(e)
        })
      })

    },

    getAllProjects({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get("/projects").then(r => {
          state.projects = r.data.data
          resolve(r)
        }).catch(e => {
          reject(e)
        })
      })
    },

    allEnterpriseProjects({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get("/projects", data).then(r => {
          resolve(r)
          commit('FETCH_ALL_ENTERPRISE_PROJECTS', r.data.data)
        }).catch(e => {
          reject(e)
        })
      })
    },

    projectGlobalFilter({
      commit
    }, {
      collaboratorId,
      customerId,
      startDate,
      endDate,
    }) {
      return new Promise((resolve, reject) => {
        axios.get(`/project-global-filter?user_id=${collaboratorId}&customer_id=${customerId}&start_date=${startDate}&end_date=${endDate}`)
          .then(response => {
            resolve(response)
            commit("FETCH_ALL_PROJECTS", response.data.data)
          }).catch(error => {
            reject(error)
          })
      })
    },

    filterByName({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/project-name-filter?name=${data}`)
          .then(response => {
            resolve(response)
            commit("FETCH_ALL_PROJECTS", response.data.data)
          }).catch(error => {
            reject(error)
          })
      })
    },

    projectTasks({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('/project-tasks/' + id)
          .then(response => {
            resolve(response)
            commit("FETCH_ALL_PROJECT_TASKS", response.data.data)
          }).catch(error => {
            reject(error)
          })
      })
    },

    editProject({ commit }, formData) {
      formData.append('_method', 'PUT')
      return new Promise((resolve, reject) => {
        axios.post('/project/' + formData.get('id'), formData)
          .then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })
      })
    },

    makeComment({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios.post('/make-comment', formData)
          .then(response => {
            resolve(response)
            // commit('FETCH_COMMENTS', response.data)
          }).catch(error => {
            reject(error)
          })
      })
    },

    accepteCustomerProject({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios.post('/state-of-project', formData)
          .then(response => {
            resolve(response)
            // commit('FETCH_COMMENTS', response.data)
          }).catch(error => {
            reject(error)
          })
      })
    },

    associateMaster({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios.post('/associate-master', formData)
          .then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })
      })
    },

    getProjectComments({ commit }, id, data) {
      return new Promise((resolve, reject) => {
        axios.get('/project-comments/' + id, data)
          .then(response => {
            resolve(response)
            commit("FETCH_COMMENTS", response.data)
          }).catch(error => {
            reject(error)
          })
      })
    },

    getProjectProgress({ commit }, id, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/project/${id}/progress`, data)
          .then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })
      })
    },

    deletePendingProject({ commit }, id, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/pending-project/${id}`, data)
          .then(response => {
            resolve(response)
          }).catch(error => {
            reject(error)
          })
      })
    },

    getAllCustomersProjects({ commit }, id, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/get-all-customers-projects`, data)
          .then(response => {
            resolve(response)
            commit('FETCH_ALL_CUSTOMERS_PROJECTS', response.data.data)
          }).catch(error => {
            reject(error)
          })
      })
    },

  }
};
