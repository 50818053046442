import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: routes,
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {

  const context = { to, from, next, router }
  if (to.meta.middleware) {

    to.meta.middleware.forEach((middleware, index) => {

      if ((index + 1) == to.meta.middleware.length) {
        //c'est le dernier middleware on peut faire next
        middleware(context)

      } else {
        middleware(context, false)

      }


    });
  }


  return next()
})





export default router
